import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
// 实现懒加载
// 路由
const Home = lazy(() => import("../components/home"))
const Shop = lazy(() => import("../components/shop"))
const Account = lazy(() => import("../components/account"))
const MyQuantify = lazy(() => import("../components/my_quantify"))
const MyProfit = lazy(() => import("../components/my_profit"))
// const BindAPI = lazy(() => import("../components/bindAPI"))
const Invite = lazy(() => import("../components/invite"))
const Guide = lazy(() => import("../components/guide"))
const Login = lazy(() => import('../components/login'))
const MyLayout = lazy(() => import("../components/layout"))
const Registe = lazy(() => import("../components/registe"))
const Terminal = lazy(() => import("../components/terminal"))

var my_routes = [
    {
        path: '/',
        element: <Login/>
    },
    // {
    //   path: '/registe',
    //   element: <Registe/>
    // },
    {
        path: '/home',
        element: <Home/>
    },
    {
        path: '/shop',
        element: <Shop/>
    },
    {
        path: '/account',
        element: <Account/>,
    },
    {
        path: '/my_quantify',
        element: <MyQuantify/>,
    },
    {
        path: '/my_profit',
        element: <MyProfit/>,
    },
    // {
    //     path: '/bindAPI',
    //     element: <BindAPI/>,
    // },
    {
        path: '/invite',
        element: <Invite/>,
    },
    {
        path: '/guide',
        element: <Guide/>,
    },
    {
      path: '/terminal',
      element: <Terminal/>
    }
]

const AppRouter = () => {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/registe" element={<Registe />}/>
        {/* 使用 route 来包裹需要布局的路由 */}
        <Route path="/" element={<MyLayout />}>
          {my_routes.map((route) => {
            if (route.path !== '/' ) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              );
            }
            return null;
          })}
        </Route>
      </Routes>
    );
  };
export default AppRouter