import { Suspense } from 'react';

import { Spin } from 'antd';
import AppRouter from '../routes';

function MyApp() {
  return (
    <Suspense fallback={<Spin size='large'/>}>
      <AppRouter/>
    </Suspense>
  );
}

export default MyApp;